.wrapper{
    @apply flex-1 flex flex-col overflow-auto;
    @apply md:overflow-hidden;
}
.tabs{
    @apply !relative flex-1 flex flex-col overflow-visible;
    @apply md:max-h-full md:overflow-hidden;
    /*@apply pt-[60px] relative;*/
}
:global(.sticky_filter) .tabs{
    @apply overflow-auto;
}
.tabs :global .stickyButton{
    @apply bottom-0 z-[11];
}
.tabs :global .stickyButton.active{
    @apply bottom-[calc(var(--stickyHeight)+0.375rem)]; ;
}
.center{
    @apply w-full flex items-center justify-center text-[1.2rem] italic;
}
:global(.filterIsActive) .wrapper{
    @apply md:overflow-hidden;
}